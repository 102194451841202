import React from 'react';
import { NavLink } from 'react-router-dom';

import './Header.scss';

const Header = () => {
  return (
    <div className='test__header'>
      <div style={{ width: '100%' }}>
        <NavLink to='/1' style={{ marginRight: '30px' }}>Задача 1</NavLink>
        <NavLink to='/2'>Задача 2</NavLink>
      </div>
    </div>
  );
};

export default Header;
