import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

import Table from './pages/table';

import './App.scss';

const App = () => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === '/') {
      history.push('/1');
    }
  }, [history]);

  return (
    <>
      <Route exact path='/1' component={Table} />
      <Route exact path='/2' component={Table} />
    </>
  );
};

export default App;
