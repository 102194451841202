import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import Header from '../../components/Header';

import { GET_USERS } from '../../settings/endpoint';

import './Table.scss';

const Table = () => {
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [pack, setPack] = useState(1);

  useEffect(() => {
    axios.get(GET_USERS(location.pathname === '/1' ? 1 : 2))
      .then(res => setUsers([ ...res.data.table ]))
  }, [location]);

  return (
    <div className='test-wrapper'>
      <div className='test'>
        <Header />
        <div className='test__question-wrapper'>
          <table cellSpacing='0'>
            <thead>
              <tr>
                <td>Место</td>
                <td>Команда</td>
                <td>Решений</td>
                <td>Результат</td>
                <td style={{ textAlign: 'right' }}>Последнее решение</td>
              </tr>
            </thead>
            <tbody>
              {users && (
                users.sort((a, b) => b.pubScore - a.pubScore).filter((i, j) => j < pack * 10).map((user, j) => {
                  return (
                    <tr key={`${user._id}`}>
                      <td>{j + 1}</td>
                      <td>{user.commandname}</td>
                      <td>{user.countSol}</td>
                      <td>{user.pubScore}</td>
                      <td style={{ textAlign: 'right' }}>{user.lastSol.replace(/(Z)|(T)/g, ' ').slice(0, 16)}</td>
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>
        </div>
        {users && users.length - pack * 10 > 0 && (
            <button onClick={() => setPack(pack + 1)}>Показать ещё</button>
          )}
      </div>
    </div>
  );
};

export default Table;
